.standardDowndefault {
  display: flex;
  min-width: 135px;
  min-height: 60px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--marginXS, 8px);
  flex: 1 0 0;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg, #f5f5f5);
}

.defaultUpperThird {
  display: flex;
  align-items: center;
  gap: var(--marginSM, 12px);
  align-self: stretch;
}

.iconBoxBlue {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  background: var(--Colors-Brand-Primary-colorPrimary);
}
.iconSmiley {
  font-size: 24px;
  color: var(--Colors-Neutral-colorWhite);
}
.TextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.StatisticTitle {
  align-self: stretch;
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 10px;
  font-style: normal;
  line-height: 10px; /* 100% */
}
.StatsNumbers {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: var(--marginXXS);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ringBox {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: rgba(22, 119, 255, 0);
}
.ring {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.defaultUpperThirdNoLine {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: var(--marginSM, 12px);
  align-self: stretch;
}

.TextBox {
  display: flex;
  align-items: center;
}

.OuterFrame {
  display: flex;
  width: 316px;
  min-width: 280px;
  padding: var(--Space-Margin-marginXS, 8px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg, #f5f5f5);
}

.UpperFrame {
  display: flex;
  padding-bottom: var(--Space-Margin-marginXS, 8px);
  align-items: center;
  align-self: stretch;
  border-bottom: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
}

.StatsFrame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-right: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
}

.FrameBelow {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.DifferenceFrame {
  display: flex;
  align-items: center;
  flex: 1 0 0;
}

.TextDifferenceFrame {
  display: flex;
  width: 85px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.DifferenceText {
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.DeltaFrame {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.DeltaIcon {
  width: 14px;
  height: 14px;
}

.DeltaText {
  color: var(--colorSuccessBase, #52c41a);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}
