.TopBar {
  display: flex;
  flex-direction: "row";
  width: 100%;
  padding: var(--Space-Padding-padding, 16px)
    var(--controlInteractiveSize, 16px);
  padding-left: 0px;
  align-items: center;
  gap: var(--Space-Padding-paddingLG);
}

.MenuIconLogoFrame {
  display: flex;
  align-items: center;
  gap: var(--Space-Padding-paddingXS, 48px);
  flex-shrink: 0;
}

.MenuIconDiv {
  display: flex;
  justify-content: center;
  line-height: 0px;
}

.IconTopbar {
  font-size: 24px;
  flex-shrink: 0;
  color: var(--Colors-Neutral-Text-colorText);
  cursor: pointer;
}

.LogoIntellitwinFrame {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 4px);
}

.Intellitwin {
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: var(--Typography-Font-Size-fontSizeXL);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Typography-Line-Height-lineHeight);
  /* 110% */
}

.menuTextBox {
  display: flex;
  max-width: 500px;
  align-items: flex-end;
  gap: var(--Space-Margin-marginXS, 8px);
  flex: 1 0 0;
}

.menuText {
  flex: 1 0 0;
  align-self: stretch;
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: var(--Typography-Font-Size-fontSizeLG);
  font-style: normal;
  font-weight: 700;
  line-height: var(--Typography-Line-Height-lineHeightSM, 20px);
  /* 125% */
}

.MenuBoxRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--Space-Padding-paddingXL);
  flex: 1 0 0;
}

.SearchBar {
  display: flex;
  width: 300px;
  max-width: 300px;
  align-items: flex-start;
  border-radius: var(--Components-Input-Global-borderRadius, 6px);
  border: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Components-Input-Global-colorBorder, #d9d9d9);
  background: var(--Components-Input-Global-colorBgContainer, #fff);
}

.Searchfunction {
  height: 22px;
  color: var(
    --Components-Input-Global-colorTextPlaceholder,
    rgba(0, 0, 0, 0.25)
  );
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--Components-Input-Global-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Input-Global-lineHeight, 22px);
  /* 157.143% */
}

.InputBasic {
  display: flex;
  padding: 0px var(--Components-Input-Component-paddingInline, 11px);
  align-items: center;
  gap: var(--Size-Base-sizeXS);
  flex: 1 0 0;
}

.ContentSearchBar {
  display: flex;
  height: var(--Components-Input-Global-controlHeight, 32px);
  padding: var(--Components-Input-Component-paddingBlock, 4px) 0px;
  align-items: center;
  gap: var(--Size-Base-sizeXS);
  flex: 1 0 0;
}

.SearchIcon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  display: block;
  color: var(--Colors-Brand-Primary-colorPrimary);
}

.Avatar {
  display: flex;
  width: var(--Components-Avatar-Component-containerSize, 32px);
  height: var(--Components-Avatar-Component-containerSize, 32px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--Size-Base-sizeXS);
  border-radius: 96px;
}
