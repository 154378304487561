.LegendBox {
  display: flex;
  width: 100%;
  /* Nimmt die gesamte Breite des übergeordneten Containers ein */
  padding: var(--Space-Margin-marginSM, 12px);
  justify-content: space-between;
  /* Gleichmäßige Verteilung des Inhalts */
  align-items: center;
  gap: 12px;
  /* Gleicher Abstand wie in der store_legend */
  flex-wrap: wrap;
  border-radius: var(--Border-Radius-borderRadius, 6px);
}

.CategoryLegendBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXXS, 4px);
}

.CategoryTitle {
  color: var(--Base-colorBlack, #1d2129);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
  opacity: var(--Alpha-alpha90, 0.9);
}

.FrameCategory {
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
}

.ItemFrame {
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
}

.ItemText {
  color: var(--Base-colorBlack, #1d2129);

  /* Text Small/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  opacity: var(--Alpha-alpha90, 0.9);
}

.NodeStatusRed {
  display: flex;
  width: var(--Size-Base-sizeSM, 12px);
  height: var(--Size-Base-sizeSM, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  background: var(--Colors-Brand-Error-colorError, #e92323);
}

.NodeStatusYellow {
  display: flex;
  width: var(--Size-Base-sizeSM, 12px);
  height: var(--Size-Base-sizeSM, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  background: var(--Colors-Brand-Warning-colorWarning, #f6d129);
}

.NodeStatusGreen {
  display: flex;
  width: var(--Size-Base-sizeSM, 12px);
  height: var(--Size-Base-sizeSM, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  background: var(--Colors-Brand-Success-colorSuccess);
}

.NodeStatusGrey {
  display: flex;
  width: var(--Size-Base-sizeSM, 12px);
  height: var(--Size-Base-sizeSM, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 9999px;
  background: rgba(0, 0, 0, 0.45);
}

.PurchaseNodeGrey {
  display: flex;
  width: 18px;
  height: 17px;
  padding: 4px var(--Space-Margin-marginXXS, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-Border-colorBorder);
}

.PurchaseNodeLetter {
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PurchaseNodeGreen {
  display: flex;
  width: 18px;
  height: 18px;
  padding: var(--Space-Margin-marginXXS, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  border: 1px solid var(--Colors-Brand-Success-colorSuccess);
  background: var(--Colors-Neutral-Border-colorBorder);
}

.PurchaseNodeRed {
  display: flex;
  width: 18px;
  height: 17px;
  padding: 4px var(--Space-Margin-marginXXS, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-Border-colorBorder);
  border: 1px solid var(--Colors-Brand-Error-colorError, #e92323);
}

.NumberBoxBlue {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 4px var(--Space-Margin-marginXXS, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimary, #0d61ff);
}

.NumberWhite {
  color: #fff;
  font-size: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.RingFull {
  width: 18px;
  height: 18px;
  border: 1px solid var(--adagocolors-bluepalette-blue-2);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RingFullInside {
  width: 14px;
  height: 14px;
  border: 1px solid var(--adagocolors-bluepalette-blue-2);
  border-radius: 9px;
}

.PartialRing {
  width: 18x;
  height: 18px;
}
