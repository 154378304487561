.ChangeDateBox {
  height: 45px;
  width: 45px;
  padding: 10px;
  border-radius: 6px;
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
  cursor: pointer;
}

.ChangeDateIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: var(--Colors-Neutral-Icon-colorIcon);
}
