:root {
  /* Collection 1 - Light */
  --Colors-Base-Blue-1: #e6f4ff;
  --Colors-Base-Blue-2: #bae0ff;
  --Colors-Base-Blue-3: #91caff;
  --Colors-Base-Blue-4: #69b1ff;
  --Colors-Base-Blue-5: #4096ff;
  --Colors-Base-Blue-6: #1677ff;
  --Colors-Base-Blue-7: #0958d9;
  --Colors-Base-Blue-8: #003eb3;
  --Colors-Base-Blue-9: #002c8c;
  --Colors-Base-Blue-10: #001d66;
  --Colors-Base-Cyan-1: #e6fffb;
  --Colors-Base-Cyan-2: #b5f5ec;
  --Colors-Base-Cyan-3: #87e8de;
  --Colors-Base-Cyan-4: #5cdbd3;
  --Colors-Base-Cyan-5: #36cfc9;
  --Colors-Base-Cyan-6: #13c2c2;
  --Colors-Base-Cyan-7: #08979c;
  --Colors-Base-Cyan-8: #006d75;
  --Colors-Base-Cyan-9: #00474f;
  --Colors-Base-Cyan-10: #002329;
  --Colors-Base-Geekblue-1: #f0f5ff;
  --Colors-Base-Geekblue-2: #d6e4ff;
  --Colors-Base-Geekblue-3: #adc6ff;
  --Colors-Base-Geekblue-4: #85a5ff;
  --Colors-Base-Geekblue-5: #597ef7;
  --Colors-Base-Geekblue-6: #2f54eb;
  --Colors-Base-Geekblue-7: #1d39c4;
  --Colors-Base-Geekblue-8: #10239e;
  --Colors-Base-Geekblue-9: #061178;
  --Colors-Base-Geekblue-10: #030852;
  --Colors-Base-Gold-1: #fffbe6;
  --Colors-Base-Gold-2: #fff1b8;
  --Colors-Base-Gold-3: #ffe58f;
  --Colors-Base-Gold-4: #ffd666;
  --Colors-Base-Gold-5: #ffc53d;
  --Colors-Base-Gold-6: #faad14;
  --Colors-Base-Gold-7: #d48806;
  --Colors-Base-Gold-8: #ad6800;
  --Colors-Base-Gold-9: #874d00;
  --Colors-Base-Gold-10: #613400;
  --Colors-Base-Green-1: #f6ffed;
  --Colors-Base-Green-2: #d9f7be;
  --Colors-Base-Green-3: #b7eb8f;
  --Colors-Base-Green-4: #95de64;
  --Colors-Base-Green-5: #73d13d;
  --Colors-Base-Green-6: #52c41a;
  --Colors-Base-Green-7: #389e0d;
  --Colors-Base-Green-8: #237804;
  --Colors-Base-Green-9: #135200;
  --Colors-Base-Green-10: #092b00;
  --Colors-Base-Lime-1: #fcffe6;
  --Colors-Base-Lime-2: #f4ffb8;
  --Colors-Base-Lime-3: #eaff8f;
  --Colors-Base-Lime-4: #d3f261;
  --Colors-Base-Lime-5: #bae637;
  --Colors-Base-Lime-6: #a0d911;
  --Colors-Base-Lime-7: #7cb305;
  --Colors-Base-Lime-8: #5b8c00;
  --Colors-Base-Lime-9: #3f6600;
  --Colors-Base-Lime-10: #254000;
  --Colors-Base-Magenta-1: #fff0f6;
  --Colors-Base-Magenta-2: #ffd6e7;
  --Colors-Base-Magenta-3: #ffadd2;
  --Colors-Base-Magenta-4: #ff85c0;
  --Colors-Base-Magenta-5: #f759ab;
  --Colors-Base-Magenta-6: #eb2f96;
  --Colors-Base-Magenta-7: #c41d7f;
  --Colors-Base-Magenta-8: #9e1068;
  --Colors-Base-Magenta-9: #780650;
  --Colors-Base-Magenta-10: #520339;
  --Colors-Base-Orange-1: #fff7e6;
  --Colors-Base-Orange-2: #ffe7ba;
  --Colors-Base-Orange-3: #ffd591;
  --Colors-Base-Orange-4: #ffc069;
  --Colors-Base-Orange-5: #ffa940;
  --Colors-Base-Orange-6: #fa8c16;
  --Colors-Base-Orange-7: #d46b08;
  --Colors-Base-Orange-8: #ad4e00;
  --Colors-Base-Orange-9: #873800;
  --Colors-Base-Orange-10: #612500;
  --Colors-Base-Purple-1: #f9f0ff;
  --Colors-Base-Purple-2: #efdbff;
  --Colors-Base-Purple-3: #d3adf7;
  --Colors-Base-Purple-4: #b37feb;
  --Colors-Base-Purple-5: #9254de;
  --Colors-Base-Purple-6: #722ed1;
  --Colors-Base-Purple-7: #531dab;
  --Colors-Base-Purple-8: #391085;
  --Colors-Base-Purple-9: #22075e;
  --Colors-Base-Purple-10: #120338;
  --Colors-Base-Red-1: #fff1f0;
  --Colors-Base-Red-2: #ffccc7;
  --Colors-Base-Red-3: #ffa39e;
  --Colors-Base-Red-4: #ff7875;
  --Colors-Base-Red-5: #ff4d4f;
  --Colors-Base-Red-6: #f5222d;
  --Colors-Base-Red-7: #cf1322;
  --Colors-Base-Red-8: #a8071a;
  --Colors-Base-Red-9: #820014;
  --Colors-Base-Red-10: #5c0011;
  --Colors-Base-Volcano-1: #fff2e8;
  --Colors-Base-Volcano-2: #ffd8bf;
  --Colors-Base-Volcano-3: #ffbb96;
  --Colors-Base-Volcano-4: #ff9c6e;
  --Colors-Base-Volcano-5: #ff7a45;
  --Colors-Base-Volcano-6: #fa541c;
  --Colors-Base-Volcano-7: #d4380d;
  --Colors-Base-Volcano-8: #ad2102;
  --Colors-Base-Volcano-9: #871400;
  --Colors-Base-Volcano-10: #610b00;
  --Colors-Base-Yellow-1: #feffe6;
  --Colors-Base-Yellow-2: #ffffb8;
  --Colors-Base-Yellow-3: #fffb8f;
  --Colors-Base-Yellow-4: #fff566;
  --Colors-Base-Yellow-5: #ffec3d;
  --Colors-Base-Yellow-6: #fadb14;
  --Colors-Base-Yellow-7: #d4b106;
  --Colors-Base-Yellow-8: #ad8b00;
  --Colors-Base-Yellow-9: #876800;
  --Colors-Base-Yellow-10: #614700;
  --Colors-Brand-Control-controlItemBgActive: var(
    --Colors-Brand-Primary-colorPrimaryBg
  );
  --Colors-Brand-Control-controlItemBgActiveDisabled: var(
    --Colors-Neutral-Fill-colorFill
  );
  --Colors-Brand-Control-controlItemBgActiveHover: var(
    --Colors-Brand-Primary-colorPrimaryBgHover
  );
  --Colors-Brand-Control-controlItemBgHover: var(
    --Colors-Neutral-Fill-colorFillTertiary
  );
  --Colors-Brand-Control-controlOutline: rgba(5, 145, 255, 0.1);
  --Colors-Brand-Control-controlTmpOutline: var(
    --Colors-Neutral-Fill-colorFillQuaternary
  );
  --Colors-Brand-Error-colorError: #ff4d4f;
  --Colors-Brand-Error-colorErrorActive: #d9363e;
  --Colors-Brand-Error-colorErrorBg: #fff2f0;
  --Colors-Brand-Error-colorErrorBgHover: #fff1f0;
  --Colors-Brand-Error-colorErrorBorder: #ffccc7;
  --Colors-Brand-Error-colorErrorBorderHover: #ffa39e;
  --Colors-Brand-Error-colorErrorHover: #ff7875;
  --Colors-Brand-Error-colorErrorOutline: rgba(255, 38, 6, 0.06);
  --Colors-Brand-Error-colorErrorText: #ff4d4f;
  --Colors-Brand-Error-colorErrorTextActive: #d9363e;
  --Colors-Brand-Error-colorErrorTextHover: #ff7875;
  --Colors-Brand-Info-colorInfo: var(--Colors-Brand-Primary-colorPrimary);
  --Colors-Brand-Info-colorInfoActive: var(
    --Colors-Brand-Primary-colorPrimaryActive
  );
  --Colors-Brand-Info-colorInfoBg: var(--Colors-Brand-Primary-colorPrimaryBg);
  --Colors-Brand-Info-colorInfoBgHover: var(
    --Colors-Brand-Primary-colorPrimaryBgHover
  );
  --Colors-Brand-Info-colorInfoBorder: var(
    --Colors-Brand-Primary-colorPrimaryBorder
  );
  --Colors-Brand-Info-colorInfoBorderHover: var(
    --Colors-Brand-Primary-colorPrimaryBorderHover
  );
  --Colors-Brand-Info-colorInfoHover: var(
    --Colors-Brand-Primary-colorPrimaryHover
  );
  --Colors-Brand-Info-colorInfoText: var(
    --Colors-Brand-Primary-colorPrimaryText
  );
  --Colors-Brand-Info-colorInfoTextActive: var(
    --Colors-Brand-Primary-colorPrimaryTextActive
  );
  --Colors-Brand-Info-colorInfoTextHover: var(
    --Colors-Brand-Primary-colorPrimaryTextHover
  );
  --Colors-Brand-Link-colorLink: var(--Colors-Brand-Info-colorInfo);
  --Colors-Brand-Link-colorLinkActive: var(--Colors-Brand-Info-colorInfoActive);
  --Colors-Brand-Link-colorLinkHover: var(--Colors-Brand-Info-colorInfoHover);
  --Colors-Brand-Primary-colorPrimary: var(
    --adagocolors-bluepalette-blue-6-adagoblue
  );
  --Colors-Brand-Primary-colorPrimaryActive: var(
    --adagocolors-bluepalette-blue-7
  );
  --Colors-Brand-Primary-colorPrimaryBg: var(--adagocolors-bggrey);
  --Colors-Brand-Primary-colorPrimaryBgHover: var(
    --adagocolors-bluepalette-blue-2
  );
  --Colors-Brand-Primary-colorPrimaryBorder: var(
    --adagocolors-bluepalette-blue-3
  );
  --Colors-Brand-Primary-colorPrimaryBorderHover: var(
    --adagocolors-bluepalette-blue-4
  );
  --Colors-Brand-Primary-colorPrimaryHover: var(
    --adagocolors-bluepalette-blue-5
  );
  --Colors-Brand-Primary-colorPrimaryText: var(
    --adagocolors-bluepalette-blue-6-adagoblue
  );
  --Colors-Brand-Primary-colorPrimaryTextActive: var(
    --adagocolors-bluepalette-blue-7
  );
  --Colors-Brand-Primary-colorPrimaryTextHover: var(
    --adagocolors-bluepalette-blue-5
  );
  --Colors-Brand-Success-colorSuccess: var(--Colors-Base-Green-6);
  --Colors-Brand-Success-colorSuccessActive: var(--Colors-Base-Green-7);
  --Colors-Brand-Success-colorSuccessBg: var(--Colors-Base-Green-1);
  --Colors-Brand-Success-colorSuccessBgHover: var(--Colors-Base-Green-2);
  --Colors-Brand-Success-colorSuccessBorder: var(--Colors-Base-Green-3);
  --Colors-Brand-Success-colorSuccessBorderHover: var(--Colors-Base-Green-4);
  --Colors-Brand-Success-colorSuccessHover: var(--Colors-Base-Green-4);
  --Colors-Brand-Success-colorSuccessText: var(--Colors-Base-Green-6);
  --Colors-Brand-Success-colorSuccessTextActive: var(--Colors-Base-Green-7);
  --Colors-Brand-Success-colorSuccessTextHover: var(--Colors-Base-Green-5);
  --Colors-Brand-Warning-colorWarning: var(--Colors-Base-Gold-6);
  --Colors-Brand-Warning-colorWarningActive: var(--Colors-Base-Gold-7);
  --Colors-Brand-Warning-colorWarningBg: var(--Colors-Base-Gold-1);
  --Colors-Brand-Warning-colorWarningBgHover: var(--Colors-Base-Gold-2);
  --Colors-Brand-Warning-colorWarningBorder: var(--Colors-Base-Gold-3);
  --Colors-Brand-Warning-colorWarningBorderHover: var(--Colors-Base-Gold-4);
  --Colors-Brand-Warning-colorWarningHover: var(--Colors-Base-Gold-4);
  --Colors-Brand-Warning-colorWarningOutline: rgba(255, 215, 5, 0.1);
  --Colors-Brand-Warning-colorWarningText: var(--Colors-Base-Gold-6);
  --Colors-Brand-Warning-colorWarningTextActive: var(--Colors-Base-Gold-7);
  --Colors-Brand-Warning-colorWarningTextHover: var(--Colors-Base-Gold-5);
  --Colors-Neutral-Bg-colorBgContainer: var(--Colors-Neutral-colorBgBase);
  --Colors-Neutral-Bg-colorBgContainerDisabled: var(
    --Colors-Neutral-Fill-colorFillTertiary
  );
  --Colors-Neutral-Bg-colorBgElevated: var(--Colors-Neutral-colorBgBase);
  --Colors-Neutral-Bg-colorBgLayout: #f5f5f5;
  --Colors-Neutral-Bg-colorBgMask: rgba(0, 0, 0, 0.45);
  --Colors-Neutral-Bg-colorBgSpotlight: rgba(0, 0, 0, 0.85);
  --Colors-Neutral-Bg-colorBgTextActive: var(--Colors-Neutral-Fill-colorFill);
  --Colors-Neutral-Bg-colorBgTextHover: var(
    --Colors-Neutral-Fill-colorFillSecondary
  );
  --Colors-Neutral-Bg-colorBorderBg: var(--Colors-Neutral-Bg-colorBgContainer);
  --Colors-Neutral-Border-colorBorder: #d9d9d9;
  --Colors-Neutral-Border-colorBorderSecondary: #f0f0f0;
  --Colors-Neutral-Border-colorSplit: rgba(0, 0, 0, 0.06);
  --Colors-Neutral-Fill-colorFill: rgba(0, 0, 0, 0.15);
  --Colors-Neutral-Fill-colorFillAlter: var(
    --Colors-Neutral-Fill-colorFillQuaternary
  );
  --Colors-Neutral-Fill-colorFillAlterSolid: #fafafa;
  --Colors-Neutral-Fill-colorFillContent: var(
    --Colors-Neutral-Fill-colorFillSecondary
  );
  --Colors-Neutral-Fill-colorFillContentHover: var(
    --Colors-Neutral-Fill-colorFill
  );
  --Colors-Neutral-Fill-colorFillQuaternary: rgba(0, 0, 0, 0.02);
  --Colors-Neutral-Fill-colorFillSecondary: rgba(0, 0, 0, 0.06);
  --Colors-Neutral-Fill-colorFillTertiary: rgba(0, 0, 0, 0.04);
  --Colors-Neutral-Icon-colorIcon: var(--Colors-Neutral-Text-colorTextTertiary);
  --Colors-Neutral-Icon-colorIconHover: var(--Colors-Neutral-Text-colorText);
  --Colors-Neutral-Text-colorText: rgba(0, 0, 0, 0.88);
  --Colors-Neutral-Text-colorTextDescription: var(
    --Colors-Neutral-Text-colorTextTertiary
  );
  --Colors-Neutral-Text-colorTextDisabled: var(
    --Colors-Neutral-Text-colorTextQuaternary
  );
  --Colors-Neutral-Text-colorTextHeading: var(--Colors-Neutral-Text-colorText);
  --Colors-Neutral-Text-colorTextLabel: var(
    --Colors-Neutral-Text-colorTextSecondary
  );
  --Colors-Neutral-Text-colorTextLightSolid: #ffffff;
  --Colors-Neutral-Text-colorTextPlaceholder: var(
    --Colors-Neutral-Text-colorTextQuaternary
  );
  --Colors-Neutral-Text-colorTextQuaternary: rgba(0, 0, 0, 0.25);
  --Colors-Neutral-Text-colorTextSecondary: rgba(0, 0, 0, 0.65);
  --Colors-Neutral-Text-colorTextTertiary: rgba(0, 0, 0, 0.45);
  --Colors-Neutral-colorBgBase: #ffffff;
  --Colors-Neutral-colorTextBase: #000000;
  --Colors-Neutral-colorWhite: #ffffff;
  --Colors-Neutral-transparent: rgba(0, 0, 0, 0);

  /* adago colors */
  --adagocolors-bggrey: #f5f5f5;
  --adagocolors-accent-1: #9b9b9b;
  --adagocolors-bluepalette-blue-1: #e6f3ff;
  --adagocolors-bluepalette-blue-2: #b0d6ff;
  --adagocolors-bluepalette-blue-3: #87bdff;
  --adagocolors-bluepalette-blue-4: #5ea1ff;
  --adagocolors-bluepalette-blue-5: #3683ff;
  --adagocolors-bluepalette-blue-10: #001666;
  --adagocolors-bluepalette-blue-6-adagoblue: #0d61ff;
  --adagocolors-bluepalette-blue-7: #0045d9;
  --adagocolors-bluepalette-blue-8: #0033b3;
  --adagocolors-bluepalette-blue-9: #00238c;
}
