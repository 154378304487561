/* used dimensions:
diameter of circle = 50px
radius of circle = 20px
diameter of donuthole = 0.8 * 50 px
width of donut / ring = 0.2 * 50px */

.frame {
  display: inline-flex;
  cursor: pointer;
}

.circle-container {
  display: flex;
  width: 50px;
  height: 50px;
  z-index: 11;
}

.text-container {
  display: flex;
  width: calc(50px * 0.8);
  height: calc(50px * 0.8);
  border-radius: 25px;
  background: #389e0d;
  position: absolute;
  left: calc(25px * 0.2);
  top: calc(25px * 0.2);
}

.text-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  width: 100%;
}

.ring-text {
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: white;
  max-width: calc(40px * 0.8);
  /* align-items: center;
  justify-content: center; */
}

.ring {
  position: absolute;
  left: 0px;
  top: 0px;
}

.item-name {
  padding-right: var(--paddingXS);
  padding-left: calc(25px + var(--paddingXXS));
  justify-content: center;
  align-items: center;
  border-radius: var(--paddingMD, 20px);
  position: absolute;
  left: 25px;
  top: calc(0.1 * 50px);
  height: calc(50px * 0.8);
  vertical-align: middle;
}

.item-text-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.item-text {
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  margin: 0px;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}
