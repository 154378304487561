.Box {
  display: flex;
  /* padding: 8px; */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-colorBgBase, #fff);
}

.GeneralInfo {
  display: flex;
  padding: 0px var(--Space-Margin-marginSM, 12px);
  /* padding-top: 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-colorBgBase, #fff);
}

.Titles {
  align-self: stretch;
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: 14px;
  font-style: normal;
  font-weight: var(--Components-Typography-Global-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  width: 100%;
  /* 218.182% */
}

.StatsCardsFrame {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  background: #fff;
}

.PicksFrame {
  flex: 1;
  min-width: 120px;
  display: inline-flex;
  padding: var(--marginXS);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--marginXS);
  border-radius: var(--borderRadius);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
}

.StatisticTitle {
  align-self: stretch;
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: 10px;
  font-style: normal;
  line-height: 10px;
}

.PickNumbersBox {
  display: flex;
  /* padding: 0px var(--Space-Margin-marginSM, 12px); */
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.PickNumbersText {
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: var(--Typography-Font-Size-fontSizeLG, 16px);
  font-style: normal;
  font-weight: var(--Components-Typography-Global-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  /* 150% */
}

.PickPercentText {
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: var(--Typography-Font-Size-fontSizeLG, 16px);
  font-style: normal;
  /* font-weight: 400; */
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  padding-left: 4px;
}
