:root {
  --Size-sizeStep: 4px;
  --Size-sizeUnit: 4px;
  --Size-controlInteractiveSize: 16px;
  --Size-sizePopupArrow: 16px;
  --Size-Base-sizeXXS: 4px;
  --Size-Base-sizeXS: 8px;
  --Size-Base-sizeSM: 12px;
  --Size-Base-size: 16px;
  --Size-Base-sizeMS: 16px;
  --Size-Base-sizeMD: 20px;
  --Size-Base-sizeLG: 24px;
  --Size-Base-sizeXL: 32px;
  --Size-Base-sizeXXL: 48px;
  --Size-Height-controlHeight: 32px;
  --Size-Height-controlHeightLG: 40px;
  --Size-Height-controlHeightSM: 24px;
  --Size-Height-controlHeightXS: 16px;
  --Size-Line-Width-lineWidth: 1px;
  --Size-Line-Width-lineWidthBold: 2px;
  --Size-Line-Width-lineWidthFocus: 4px;
  --Size-Line-Width-controlOutlineWidth: 2px;
  --Size-Screen-Size-screenLG: 992px;
  --Size-Screen-Size-screenLGMax: 1199px;
  --Size-Screen-Size-screenLGMin: var(--Size-Screen-Size-screenLG);
  --Size-Screen-Size-screenMD: 768px;
  --Size-Screen-Size-screenMDMax: 991px;
  --Size-Screen-Size-screenMDMin: var(--Size-Screen-Size-screenMD);
  --Size-Screen-Size-screenSM: 576px;
  --Size-Screen-Size-screenSMMax: 767px;
  --Size-Screen-Size-screenSMMin: var(--Size-Screen-Size-screenSM);
  --Size-Screen-Size-screenXL: 1200px;
  --Size-Screen-Size-screenXLMax: 1599px;
  --Size-Screen-Size-screenXLMin: var(--Size-Screen-Size-screenXL);
  --Size-Screen-Size-screenXS: 480px;
  --Size-Screen-Size-screenXSMax: 575px;
  --Size-Screen-Size-screenXSMin: var(--Size-Screen-Size-screenXS);
  --Size-Screen-Size-screenXXL: 1600px;
  --Size-Screen-Size-screenXXLMin: var(--Size-Screen-Size-screenXXL);
  --Space-Margin-margin: var(--Size-Base-size);
  --Space-Margin-marginLG: var(--Size-Base-sizeLG);
  --Space-Margin-marginMD: var(--Size-Base-sizeMD);
  --Space-Margin-marginSM: var(--Size-Base-sizeSM);
  --Space-Margin-marginXL: var(--Size-Base-sizeXL);
  --Space-Margin-marginXS: var(--Size-Base-sizeXS);
  --Space-Margin-marginXXL: var(--Size-Base-sizeXXL);
  --Space-Margin-marginXXS: var(--Size-Base-sizeXXS);
  --Space-Padding-padding: var(--Size-Base-size);
  --Space-Padding-paddingLG: var(--Size-Base-sizeLG);
  --Space-Padding-paddingMD: var(--Size-Base-sizeMD);
  --Space-Padding-paddingSM: var(--Size-Base-sizeSM);
  --Space-Padding-paddingXL: var(--Size-Base-sizeXL);
  --Space-Padding-paddingXS: var(--Size-Base-sizeXS);
  --Space-Padding-paddingXXS: var(--Size-Base-sizeXXS);
  --Space-Padding-paddingContentHorizontal: var(--Size-Base-sizeMS);
  --Space-Padding-paddingContentHorizontalLG: var(--Size-Base-sizeLG);
  --Space-Padding-paddingContentHorizontalSM: var(--Size-Base-size);
  --Space-Padding-paddingContentVertical: var(--Size-Base-sizeSM);
  --Space-Padding-paddingContentVerticalLG: var(--Size-Base-sizeMS);
  --Space-Padding-paddingContentVerticalSM: var(--Size-Base-sizeXS);
  --Space-Padding-controlPaddingHorizontal: 12px;
  --Space-Padding-controlPaddingHorizontalSM: 8px;
  --Border-Radius-borderRadius: 6px;
  --Border-Radius-borderRadiusLG: 8px;
  --Border-Radius-borderRadiusSM: 4px;
  --Border-Radius-borderRadiusXS: 2px;
}
