.FullBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--Space-Margin-marginSM);
  gap: var(--paddingXL, 32px);
}

.UpperBox {
  display: flex;
  align-items: top;
  align-self: stretch;
  gap: var(--Size-Base-sizeXXL);
}

.TextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Size-Base-sizeXL);
  flex: 1 0 0;
}

.CatchySlogan {
  align-self: stretch;
  color: var(--Typography-colorTextHeading, rgba(0, 0, 0, 0.88));
  font-size: var(--Typography-Font-Size-fontSizeHeading1);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightStrong);
  padding-top: var(--Space-Padding-paddingXL);
}

.Subtitle {
  align-self: stretch;
  color: var(--Colors-Neutral-colorTextBase, #000);
  font-size: var(--Typography-Font-Size-fontSizeHeading3);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightStrong);
  line-height: var(--Typography-Line-Height-lineHeightHeading3);
}

.LowerBox {
  width: 100%;
  display: flex;
  max-width: 1000px;
  flex-wrap: wrap;
  /* gap: var(--Space-Margin-marginXXL); */
  justify-content: space-around;
}
