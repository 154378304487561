:root {
  --Typography-Font-Size-fontSize: 14px;
  --Typography-Font-Size-fontSizeLG: 16px;
  --Typography-Font-Size-fontSizeSM: 12px;
  --Typography-Font-Size-fontSizeXL: 20px;
  --Typography-Font-Size-fontSizeHeading1: 38px;
  --Typography-Font-Size-fontSizeHeading2: 30px;
  --Typography-Font-Size-fontSizeHeading3: 24px;
  --Typography-Font-Size-fontSizeHeading4: 20px;
  --Typography-Font-Size-fontSizeHeading5: 16px;
  --Typography-Line-Height-lineHeight: 22px;
  --Typography-Line-Height-lineHeightLG: 24px;
  --Typography-Line-Height-lineHeightSM: 20px;
  --Typography-Line-Height-lineHeightHeading1: 46px;
  --Typography-Line-Height-lineHeightHeading2: 38px;
  --Typography-Line-Height-lineHeightHeading3: 32px;
  --Typography-Line-Height-lineHeightHeading4: 28px;
  --Typography-Line-Height-lineHeightHeading5: 24px;
  --Typography-Font-Size-fontSizeIcon: 12px;
  --Typography-Font-Weight-fontWeightNormal: 400;
  --Typography-Font-Weight-fontWeightStrong: 600;
}
