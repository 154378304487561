.CurrentTimeBox {
  display: inline-flex;
  padding: var(--Space-Margin-marginXS, 8px) var(--Space-Margin-margin, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Space-Margin-marginSM, 12px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
  /* background: rgba(217, 217, 217, 0.34); */
}

.ClockIcon {
  width: 24px;
  height: 24px;
}

.TimeWrapper {
  display: flex;
  align-items: center;
  gap: var(--Components-Statistic-Global-marginXXS, 4px);
}

.Time {
  /* color: #818181; */
  font-size: 14px;
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightStrong, 600);
  /* 228.571% */
}

.TimeSliderAll {
  display: flex;
  padding: var(--Space-Margin-marginXS, 8px) var(--Space-Margin-margin, 16px);
  align-items: center;
  gap: var(--Space-Margin-marginMD, 20px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
  width: 100%;
}

.BoxLeft {
  display: flex;
  width: 166px;
  padding: var(--Space-Margin-marginXS, 8px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  flex-shrink: 0;
  align-self: stretch;
}

.PlayPauseFrame {
  display: flex;
  padding: 0px var(--paddingXS, 8px);
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  color: var(
    --Components-Typography-Global-colorTextDescription,
    rgba(0, 0, 0, 0.45)
  );
  padding-left: 50px;
}

.PlayPauseIcon {
  font-size: 38px;
  color: var(
    --Components-Typography-Global-colorTextDescription,
    rgba(0, 0, 0, 0.45)
  );
}

.speedText {
  color: var(
    --Components-Typography-Global-colorTextDescription,
    rgba(0, 0, 0, 0.45)
  );
  font-size: var(--Components-Typography-Global-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Typography-Global-lineHeight, 22px);
  /* 157.143% */
}

.BoxRight {
  display: flex;
  padding: var(--Space-Margin-marginXS, 8px) 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.DateDisplayed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
}
