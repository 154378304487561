:root {
  --colorBgContainer: #ffffff;
  --colorBgLayout: #f0f2f5;
  --colorBorder: #d9d9d9;
  --colorSplit: #f0f0f0;
  --colorTextSecondary: #8c8c8c;
  --colorTextPrimary: #000000;
  --Colors-Neutral-colorBgBase: #fff;
  --Colors-Brand-Primary-colorPrimary: #0d61ff;
  --Colors-Brand-Primary-colorPrimaryBg: #f5f5f5;
  --blue-palette-blue-1: #e6f3ff;
  --fontSizeHeading2: 24px;
  --fontSizeHeading3: 20px;
  --lineHeight: 1.5;
  --marginXXS: 4px;
  --marginXS: 8px;
  --marginSM: 12px;
  --padding: 16px;
  --paddingLG: 24px;
  --paddingXS: 8px;
  --paddingXXS: 4px;
  --borderRadius: 6px;
  --borderRadiusLG: 8px;
  --screenXL: 1200px;
  --iconSize: 24px;
  --sizeXXL: 32px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
