.CardBox {
  display: flex;
  width: 250px;
  /* max-width: 400px; */
  height: 370px;
  padding: var(--controlInteractiveSize, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--paddingMD, 20px);
  flex-shrink: 0;
  border-radius: var(--borderRadiusLG, 8px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
  cursor: pointer;
}

.IconBox {
  display: flex;
  padding: var(--Space-Margin-margin, 16px) 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-colorBgBase, #fff);
}

.Icon {
  display: flex;
  font-size: 130px;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS);
  flex-shrink: 0;
  color: var(--adagocolors-bluepalette-blue-10);
}

.CardTextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginSM);
  align-self: stretch;
}

.Title {
  align-self: stretch;
  color: var(--Colors-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
  font-size: var(--Typography-Font-Size-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeight, 22px);
}

.Description {
  color: var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65));
  font-size: var(--Typography-Font-Size-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Typography-Line-Height-lineHeight, 22px);
  /* 157.143% */
}
