.OuterBox {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Size-Base-sizeXL);
}

.Graphic {
  height: 380px;
  flex-shrink: 0;
}

.ComingSoon {
  color: var(--Typography-colorTextHeading, rgba(0, 0, 0, 0.88));
  text-align: center;
  font-size: var(--Typography-Font-Size-fontSizeHeading1);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightStrong);
  line-height: var(--Typography-Line-Height-lineHeightHeading1);
  /* 97.222% */
  align-self: stretch;
}

.ExplanationParagraph {
  align-self: stretch;
  color: var(--Typography-colorTextHeading, rgba(0, 0, 0, 0.88));
  text-align: center;
  font-size: var(--Typography-Font-Size-fontSizeXL);
  font-style: normal;
  line-height: var(--Typography-Line-Height-lineHeightLG);
  /* 175% */
}
