.Box {
  display: flex;
  /* padding: 8px; */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-colorBgBase, #fff);
}

.GeneralInfo {
  display: flex;
  padding: 0px var(--Space-Margin-marginSM, 12px);
  /* padding-top: 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Neutral-colorBgBase, #fff);
}

.Titles {
  align-self: stretch;
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: 14px;
  font-style: normal;
  font-weight: var(--Components-Typography-Global-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  width: 100%;
  /* 218.182% */
}

.StatsCardsFrame {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  background: #fff;
}

.Radio {
  display: flex;
  height: 32px;
  align-items: center;
  align-self: stretch;
}

.RadioButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.ShoppingItemBox {
  display: flex;
  padding: var(--Space-Margin-marginSM, 12px);
  align-items: center;
  align-self: stretch;
  gap: var(--Space-Margin-marginSM, 12px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
  width: 100%;
}

.NotAvailableBox {
  width: 30px;
  height: 30px;
  border-radius: var(--Border-Radius-borderRadius);
  background: var(--Colors-Brand-Error-colorError);
}

.ShoppingListIcon {
  width: 30px;
  height: 30px;
  fill: white;
}

.AvailableBox {
  width: 30px;
  height: 30px;
  border-radius: var(--Border-Radius-borderRadius);
  background: var(--Colors-Brand-Success-colorSuccess);
}

.NotYetGrabbed {
  width: 30px;
  height: 30px;
  background: var(--Colors-Neutral-Border-colorBorder);
  border-radius: var(--Border-Radius-borderRadius);
}

.Item {
  flex: 1 0 0;
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: var(--Typography-Font-Size-fontSizeLG, 16px);
  font-style: normal;
  font-weight: var(--Components-Typography-Global-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  /* 150% */
}

.PurchaseTime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: var(--Colors-Neutral-colorTextBase, #000);
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  /* 100% */
}
