.noDataBox {
  display: flex;
  width: 661px;
  flex-direction: column;
  padding: var(--Components-Notification-Global-paddingMD, 20px)
    var(--Components-Notification-Global-paddingContentHorizontalLG, 24px);
  align-items: flex-start;
  gap: var(--Components-Notification-Global-marginSM, 12px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg, #f5f5f5);
  position: absolute;
  z-index: 2;
  justify-content: center;
  align-content: center;
  /* boxShadow */
  box-shadow:
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.upperBox {
  display: flex;
  gap: 12px;
  align-self: stretch;
  flex-direction: row;
}

.iconAusrufezeichen {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: var(--Colors-Brand-Primary-colorPrimary);
}

.TextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 4px;
}

.Title {
  display: flex;
  width: flex;
  height: 24px;
  align-items: center;
  gap: var(--Components-Typography-Global-marginXXS, 4px);
  color: var(
    --Components-Typography-Global-colorTextHeading,
    rgba(0, 0, 0, 0.88)
  );
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-lineHeightLG, 24px);
  /* 171.429% */
}

.Text {
  align-self: stretch;
  color: var(--Components-Notification-Global-colorText, rgba(0, 0, 0, 0.88));
  font-size: var(--Components-Notification-Global-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Components-Notification-Global-lineHeight, 22px);
  /* 157.143% */
  padding-bottom: 8px;
  padding-top: 8px;
}

.closeIcon {
  display: flex;
  width: 22px;
  height: 22px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 20px;
  border-radius: var(--Border-Radius-borderRadiusSM, 4px);
}

.ButtonBox {
  display: flex;
  justify-content: right;
  width: 100%;
  padding-top: var(--Space-Padding-paddingXS);
}

.Button {
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInlineSM, 7px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Components-Button-Global-borderRadiusSM, 4px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimary);

  /* Component/Button/primaryShadow */
  box-shadow: 0px var(--Components-Button-Global-controlOutlineWidth, 2px) 0px
    0px var(--Components-Button-Global-controlOutline, rgba(5, 145, 255, 0.1));
}

.ButtonWrapper {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: var(--Components-Notification-Global-marginXS, 8px);
}

.ButtonText {
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInlineSM, 7px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Components-Button-Global-borderRadiusSM, 4px);
  color: white;
}

.closeIcon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  color: grey;
}

.DatePickerBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  padding-right: 20px;
  flex-direction: column;
  width: 100%;
  /* Stelle sicher, dass die Box die volle Breite einnimmt */
}

.DatePicker {
  width: 100%;
  /* Nimmt die volle Breite innerhalb der DatePickerBox ein */
  flex-grow: 1;
  /* DatePicker dehnt sich, um verfügbaren Platz auszufüllen */
}
