.BothStoresFrame {
  display: flex;
  width: 100%;
  /* align-items: flex-start; */
  gap: 12px;
  height: 100%;
}

.LeftSide {
  display: flex;
  width: 199px;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex-shrink: 0;
  align-self: stretch;
  position: relative;
}

.OnlineStore {
  height: 70%;
  display: flex;
  padding: var(--Space-Margin-marginSM, 12px);
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 70%;
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  border: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
}

.CustomerFrame {
  display: flex;
  /* position: absolute; */
  height: 10%;
  width: 100%;
  bottom: 0;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  border: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
}

.Arrow {
  transform: rotate(90deg);
  flex: 1 0 0;
  align-self: stretch;
  stroke-width: 1px;
  stroke: var(--Colors-Neutral-Border-colorBorder, #bb1b1b);
}

.StoreFrame {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  flex: 1 0 0;
  align-self: stretch;
}

.StoreBorder {
  flex: 1 0 0;
  max-width: 100%;
  width: 100%;
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  border: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
}

.StoreName {
  color: var(--Colors-Neutral-Text-colorTextDescription);
  font-size: var(--Typography-Font-Size-fontSizeSM);
}

/* .Exit {
  display: flex;
  padding: 58px 0px;
  align-items: center;
  gap: 10px;
  position: relative;
  left: -80px;
} */
