.customerframe {
  display: flex;
  width: 31px;
  padding: 4px var(--Space-Margin-marginXXS, 4px);
  flex-direction: column;
  align-items: center;
  /* gap: var(--Space-Margin-marginXXS, 4px); */
  border-radius: var(--Border-Radius-borderRadius, 6px);
  z-index: 30;
  cursor: pointer;
}

.iconcontainer {
  display: flex;
  width: var(--Components-Button-Component-onlyIconSizeLG, 18px);
  height: var(--Components-Button-Component-onlyIconSizeLG, 18px);
  padding: 2.25px;
  justify-content: center;
  align-items: center;
}

.iconframe {
  display: flex;
  width: 13.5px;
  height: 13.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.icon {
  width: 13.5px;
  height: 13.5px;
  flex-shrink: 0;
}

.progressbar {
  line-height: 1 !important;
}
