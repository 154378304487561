.standardDowndefault {
  flex: 1;
  min-width: 120px;
  display: inline-flex;
  padding: var(--marginXS);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--marginXS);
  border-radius: var(--borderRadius);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
}

.defaultUpperThird {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: var(--marginSM, 12px);
  align-self: stretch;
  border-bottom: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--Colors-Neutral-Border-colorBorder);
}

.iconBoxBlue {
  display: flex;
  padding: var(--borderRadius);
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--borderRadius);
  background: var(--Colors-Brand-Primary-colorPrimary);
}

.iconSmiley {
  font-size: 24px;
  color: var(--Colors-Neutral-colorWhite);
}

.TextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.StatisticTitle {
  align-self: stretch;
  color: var(--Colors-Neutral-colorTextBase, #000);

  font-size: 10px;
  font-style: normal;
  line-height: 10px;
  /* 100% */
}

.StatsNumbers {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: var(--marginXXS);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.Line {
  height: 0px;
  align-self: stretch;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.deltaFrame {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.iconArrowDown {
  font-size: 10px;
  color: var(--Colors-Brand-Error-colorError);
}

.NumbersDelta {
  display: flex;
  width: 24px;
  height: var(--Alpha-alpha10, 10px);
  flex-direction: column;
  justify-content: center;
  color: var(--Colors-Brand-Error-colorError);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  /* 100% */
}

.TextDelta {
  flex: 1 0 0;
  color: var(--Colors-Neutral-colorTextBase);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  /* 100% */
}

.iconArrowUp {
  font-size: 10px;
  color: var(--Colors-Brand-Success-colorSuccess);
}

.NumbersDeltaUp {
  display: flex;
  width: 24px;
  height: var(--Alpha-alpha10, 10px);
  flex-direction: column;
  justify-content: center;
  color: var(--Colors-Brand-Success-colorSuccess);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  /* 100% */
}

.ringBox {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: rgba(22, 119, 255, 0);
}

.ring {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.defaultUpperThirdNoLine {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: var(--marginSM, 12px);
  align-self: stretch;
}

.pendingOrder {
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Margin-margin, 16px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
}

.InformationFramePendingOrder {
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px) 0px;
  align-items: flex-start;
  gap: var(--Space-Margin-margin, 16px);
  border-radius: var(--Border-Radius-borderRadius, 6px);
}

.IconBoxPendingOrder {
  display: flex;
  width: var(--Components-Avatar-Component-containerSize, 32px);
  height: var(--Components-Avatar-Component-containerSize, 32px);
  padding: 7px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  border-radius: var(--Components-Avatar-Global-borderRadius, 6px);
  background: var(
    --Components-Avatar-Global-colorTextPlaceholder,
    rgba(0, 0, 0, 0.25)
  );
}

.ShoppingCartIcon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.TitlePendingOrders {
  color: var(--Components-List-Global-colorText);
  /* Text Base/Strong */
  font-size: var(--Components-Alert-Global-fontSize, 14px);
  font-style: normal;
  font-weight: var(--Components-Calendar-Global-fontWeightStrong, 600);
  line-height: var(--Typography-Line-Height-lineHeight, 22px);
  /* 157.143% */
}

.TextBoxPendingOrders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXXS, 4px);
}

.InformationPendingOrders {
  color: var(--Components-List-Global-colorTextDescription);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: var(--Typography-Line-Height-lineHeightSM, 20px);
  /* 166.667% */
}

.iconBoxGrey {
  display: flex;
  padding: var(--borderRadius);
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--borderRadius);
  background: #bfbfbf;
}

.iconBox {
  display: flex;
  padding: var(--borderRadius);
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--borderRadius);
}

.InfoBoxGrey {
  display: flex;
  min-width: 200px;
  padding-top: 0px;
  padding-right: 8px;
  padding-bottom: 0px;
  padding-left: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--marginXS, 8px);
  flex: 1 0 0;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
}

.InformationCard {
  display: flex;
  min-width: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
}

.TextIconBox {
  display: flex;
  align-items: center;
  padding: var(--marginXS, 8px);
  gap: var(--marginSM, 12px);
  align-self: stretch;
  border-radius: var(--Border-Radius-borderRadius, 6px);
  background: var(--Colors-Brand-Primary-colorPrimaryBg);
}

.TextBox {
  display: flex;
  align-items: center;
}

.MaterialTitle {
  display: flex;
  align-items: center;
  gap: var(--Components-Typography-Global-marginXXS);
  color: var(--Components-Typography-Global-colorTextHeading);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px !important;
}

.ShoppingIcon {
  font-size: 20px;
  color: var(--Colors-Neutral-colorTextBase);
}
