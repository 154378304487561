.OuterFrame {
  display: flex;
  width: 140px;
  align-items: center;
  gap: 10px;
  position: relative;
}

.ExitFrame {
  display: flex;
  height: 50px;
  min-width: 50px;
  padding: var(--Space-Margin-marginXS, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 25px;
  background: var(--adagocolors-bluepalette-blue-9);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  translate: -50% -50%;
  left: 0;
  /* Positionierung relativ zu OuterFrame */
  z-index: 1;
  /* Über dem RightFrame */
}

.LeftFrame {
  display: flex;
  height: 50px;
  min-width: 50px;
  padding: var(--Space-Margin-marginXS, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 25px;
  background: var(--blue-palette-blue-6-adago-blue, #0d61ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  /* Positionierung relativ zu OuterFrame */
  z-index: 1;
  /* Über dem RightFrame */
}

.LeftFrameEntrance {
  display: flex;
  height: 50px;
  min-width: 50px;
  padding: var(--Space-Margin-marginXS, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -4px;
  border-radius: 25px;
  background: #00238c;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  /* Positionierung relativ zu OuterFrame */
  z-index: 1;
  /* Über dem RightFrame */
}

.ContentFrameLeft {
  display: flex;
  /* width: 20px;
  height: 20px; */
  padding: 2.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 4px;
  gap: 4px;
}

.IconFrame {
  display: flex;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.Icon {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
  fill: #fff;
}

.Number {
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.RightFrame {
  display: flex;
  height: 33px;
  min-width: 70px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--Alpha-alpha10, 10px);
  border-radius: 11px;
  background: #d9d9d9;
  padding-left: 50px;
  /* Abstand links, damit der Text nicht überlappt */
  padding-right: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-sizing: border-box;
  /* Berücksichtigt Padding bei der Breite */
}

.Title {
  min-width: 70px;
  flex: 1 0 0;
  color: var(--Colors-Neutral-colorTextBase, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
}

.EntranceNumber {
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
