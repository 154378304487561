.LegendHeader {
  padding: 0px !important;
  height: auto !important;
  line-height: normal !important;
}

.LayoutSider {
  overflow: auto;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: unset;
}

.SecondLayout {
  gap: var(--Space-Margin-marginSM);
}

.Content {
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginSM, 12px) !important;
  height: 100%;
}

.NavigationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: var(--Space-Margin-marginSM, 12px);
  justify-content: center;
}

.ChangeDateContainer {
  width: 199px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.AlertBox {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}
